<template>
  <q-tabs
    :model-value="<string>$route.meta.step"
    indicator-color="secondary"
    active-class="active-tab"
    active-color="base-color"
  >
    <div
      v-for="(el, i) in bread"
      :key="el.id"
      class="flex items-center no-wrap"
    >
      <q-route-tab
        :to="
          authStore.selfUser && el.id === 'templates' ? undefined : getRoute(i)
        "
        :href="
          authStore.selfUser && el.id === 'templates'
            ? wtUrl + '/templates'
            : undefined
        "
        :name="el.id"
        :label="el.label()"
        :ripple="false"
        class="text-body1"
        :class="{
          'no-pointer-events text-grey-7':
            el.id === 'submit' || (!hasPrev && stepIndex < i),
        }"
        :data-test="el.id"
      />
      <q-icon
        v-if="i !== bread.length - 1"
        size="1.2em"
        color="muted"
        name="fa-solid fa-angle-right"
      />
    </div>
  </q-tabs>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from 'stores/sender/auth/auth'

const route = useRoute()
const { t } = useI18n()

const authStore = useAuthStore()

const wtUrl = process.env.RECEIVER_URL as string

const bread = [
  {
    id: 'templates',
    link: () => '/',
    label: () => t('common.template', 2),
  },
  {
    id: 'source',
    link: (template: string) => `/${template}`,
    label: () => t('sender.common.source'),
  },
  {
    id: 'map',
    link: (template: string, sourceId: string) =>
      `/${template}/${sourceId}/map`,
    label: () => t('sender.common.map'),
  },
  {
    id: 'review',
    link: (template: string, sourceId: string) =>
      `/${template}/${sourceId}/review`,
    label: () => t('sender.common.review'),
  },
  {
    id: 'submit',
    link: (template: string, sourceId: string, submissionId: string) =>
      `/${template}/${sourceId}/submit/${submissionId}`,
    label: () => t('sender.common.submit'),
  },
]

const stepIndex = computed(() => {
  return bread.findIndex((el) => el.id === route.meta.step)
})

const hasPrev = computed(() => authStore.prevSourceId && authStore.prevTemplate)

const getRoute = (index: number) => {
  let sourceId = route.params.sourceId
  let template = route.params.templateHandle

  if (authStore.prevSourceId) {
    sourceId = authStore.prevSourceId
  }
  if (authStore.prevTemplate) {
    template = authStore.prevTemplate
  }

  if ((sourceId && template) || index === 0 || (index === 1 && template)) {
    return bread[index].link(
      template as string,
      sourceId as string,
      route.params.submissionId as string
    )
  } else return undefined
}
</script>

<style lang="scss" scoped>
:deep(.q-tabs__content > *) {
  margin-right: 0;
}

:deep(.q-tab__indicator) {
  height: 5px;
  border-radius: 8px;
  margin: 0 16px 0 16px;
}
</style>

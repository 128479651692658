<template>
  <q-layout
    v-if="authStore.selfUser"
    view="hHh Lpr lFf"
    class="bg-background layout-anchor"
    data-test="self-layout"
  >
    <q-header
      bordered
      height-hint="70"
      class="bg-background header-height q-px-md"
    >
      <header-menu />
    </q-header>

    <side-menu
      v-if="authStore.isAuthenticated"
      :link-actions="senderLinks"
      :bin="{
        href: wtUrl + '/bin/templates',
      }"
      :active-link="
        $route.path.includes('/app/workflows') ? undefined : 'templates'
      "
    />

    <q-page-container>
      <q-page v-if="configStore.configLoaded">
        <div
          class="q-pb-lg q-pt-xl container-fluid"
          :class="$route.meta.fluid ? $route.meta.fluid : 'container-md'"
        >
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <onboarding-btn-wrapper
              v-if="
                isActive('onboarding_stepper') &&
                authStore.isAuthenticated &&
                authStore.isReceiver &&
                onboardingStore.steps &&
                !onboardingStore.previouslyFinished
              "
            />
          </transition>

          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <bread-stepper v-if="$route.meta.step" class="q-mb-xl" />
          </transition>

          <router-view v-slot="{ Component }">
            <transition
              mode="out-in"
              enter-active-class="animated fadeIn"
              leave-active-class="animated fadeOut"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </q-page>
      <!-- Config not loaded -->
      <q-page v-else class="column flex-center">
        <page-title center main-title="ooops!" :title="$t('error.something')" />

        <q-btn
          :href="wtUrl"
          :label="$t('sender.error.backToWetransform')"
          color="primary"
          class="btn-lg btn-wide"
        />
      </q-page>
    </q-page-container>
  </q-layout>
  <!-- SENDER LAYOUT -->
  <q-layout
    v-else
    view="hHh Lpr lfr"
    class="bg-background layout-anchor"
    data-test="sender-layout"
  >
    <q-header
      bordered
      height-hint="70"
      class="header-height bg-background text-title"
    >
      <q-toolbar class="full-height q-px-md">
        <q-btn
          round
          icon="menu"
          aria-label="Menu"
          flat
          color="title"
          class="lt-md"
          @click="toggleLeftDrawer"
        />
        <router-link to="/" class="flex items-center q-pl-md" data-test="logo">
          <q-img
            :src="
              authStore.isOwn
                ? logo
                : configStore.pageInfo?.organisation.picture_thumbnail_url
            "
            :alt="
              authStore
                ? 'WeTransform'
                : configStore.pageInfo?.organisation.name
            "
            width="45px"
            class="q-ml-lg q-ml-md-none"
            fit="contain"
          />
          <p
            v-if="authStore.user?.impersonator && !authStore.isReceiver"
            class="text-h4 text-title"
          >
            {{ $t('sender.auth.connectAs') }}
            {{
              authStore.user?.name ||
              authStore.user?.email ||
              authStore.user?.customer_id
            }}
          </p>
          <p
            v-else-if="authStore.isOwn"
            class="text-weTransform text-gradient q-ml-sm"
            style="font-size: 20px"
          >
            WeTransform
          </p>
          <p v-else class="text-h4 q-ml-md text-title">
            {{ configStore.pageInfo?.organisation.name }}
          </p>
        </router-link>

        <q-chip color="grey-4" class="q-ml-md"> Beta </q-chip>

        <q-space />
        <template v-if="environement !== 'prod'">
          <q-chip
            color="negative"
            size="xl"
            class="animated pulse infinite slower"
          >
            {{ environement }}
          </q-chip>
          <q-space />
        </template>

        <p v-if="!authStore.isOwn" class="gt-xs q-mr-md" data-test="moto">
          {{ configStore.pageInfo?.motto }}
        </p>

        <lang-switcher />
      </q-toolbar>
    </q-header>
    <!-- SIDE MENU -->
    <side-drawer v-if="configStore.configLoaded" />

    <!-- CONTENT -->
    <q-page-container>
      <q-page v-if="configStore.configLoaded">
        <div
          class="q-py-lg container-fluid"
          :class="$route.meta.fluid ? $route.meta.fluid : 'container-md'"
        >
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <onboarding-btn-wrapper
              v-if="
                isActive('onboarding_stepper') &&
                authStore.isAuthenticated &&
                authStore.isReceiver &&
                onboardingStore.steps &&
                !onboardingStore.previouslyFinished
              "
            />
          </transition>
          <transition
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <bread-stepper v-if="$route.meta.step" class="q-mb-xl" />
          </transition>
          <router-view v-slot="{ Component }">
            <transition
              mode="out-in"
              enter-active-class="animated fadeIn"
              leave-active-class="animated fadeOut"
            >
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </q-page>
      <!-- Config not loaded -->
      <q-page v-else class="column flex-center">
        <page-title center main-title="ooops!" :title="$t('error.something')" />

        <q-btn
          :href="wtUrl"
          :label="$t('sender.error.backToWetransform')"
          color="primary"
          class="btn-lg btn-wide"
        />
      </q-page>
    </q-page-container>

    <!-- FOOTER -->
    <q-footer class="bg-background text-dark q-py-sm" style="z-index: 1900">
      <i18n-t
        keypath="sender.footer"
        tag="p"
        scope="global"
        class="text-center"
      >
        <template #link>
          <a :href="wtUrl" target="_blank" class="text-link-wt">
            WeTransform
          </a>
        </template>
      </i18n-t>
    </q-footer>
  </q-layout>
</template>

<script setup lang="ts">
import { useConfigStore } from 'stores/sender/config'
import { useAuthStore } from 'stores/sender/auth/auth'
import { useOnboardingStore } from 'stores/common/onboarding'
import { useFeatureFlagStore } from 'stores/common/featureFlag'

import { useDrawer } from 'composables/common/layout/useDrawer'

import BreadStepper from 'components/sender/layout/BreadStepper.vue'
import SideDrawer from 'components/sender/layout/SideDrawer.vue'
import PageTitle from 'components/common/ui/pageElements/PageTitle.vue'
import LangSwitcher from 'components/common/layout/LangSwitcher.vue'
import OnboardingBtnWrapper from 'components/sender/layout/OnboardingBtnWrapper.vue'
import SideMenu from 'components/common/layout/SideMenu.vue'
import HeaderMenu from 'components/sender/layout/self/HeaderMenu.vue'

import logo from 'assets/img/logo/logo-color.png'

const { toggleLeftDrawer } = useDrawer()

const { isActive } = useFeatureFlagStore()
const configStore = useConfigStore()
const authStore = useAuthStore()
const onboardingStore = useOnboardingStore()

const environement = process.env.INFRA_ENV

const wtUrl = process.env.RECEIVER_URL as string

const senderLinks = {
  overview: {
    href: wtUrl,
    display: () =>
      authStore.selfActiveOrganisation
        ?.number_of_received_submissions_from_others !== 0,
  },
  templates: {
    href: wtUrl + '/templates',
  },
  senderWorkflows: {
    to: '/app/workflows',
    display: () =>
      authStore.selfActiveOrganisation?.number_of_self_transformations !== 0,
  },
  integrations: {
    href: wtUrl + '/integrations',
  },
}
</script>

<style scoped lang="scss">
:deep(.q-toolbar) * {
  z-index: 2101;
}
</style>

<template>
  <q-drawer
    v-model="leftDrawerOpen"
    :behavior="drawerbehave"
    persistent
    show-if-above
    :mini="true"
    :width="250"
    :mini-width="95"
    class="bg-secondary"
  >
    <div
      class="full-height column-md items-center q-px-md q-px-md-xs"
      :class="{
        'justify-between': !authStore.isOwn,
      }"
    >
      <q-list class="q-mx-none q-mx-md-auto q-mt-xl">
        <div
          v-for="link in filterdMenuLinks"
          :key="link.link"
          class="column-md justify-center items-center q-mb-md"
        >
          <q-item
            v-ripple
            :to="link.link"
            class="rounded-borders q-mb-xs"
            :class="{
              'menu-item round-border border border-text-secondary':
                $q.screen.gt.sm,
            }"
            active-class="bg-text-secondary text-secondary"
            clickable
            :data-test="link.id"
          >
            <q-item-section avatar>
              <q-icon :name="link.icon" size="xs" />
            </q-item-section>

            <q-item-section>
              <q-item-label>{{ link.label() }}</q-item-label>
            </q-item-section>

            <q-tooltip anchor="center right" self="center left">
              {{ link.tooltip() }}
            </q-tooltip>
          </q-item>
          <p class="text-center text-xs gt-sm">
            {{ link.label() }}
          </p>
        </div>
        <template v-if="authStore.isReceiver">
          <q-separator
            style="background-color: var(--q-text-secondary)"
            class="q-mx-xs"
          />
          <div class="column-md items-center">
            <q-item
              v-ripple
              :href="returnUrl"
              clickable
              class="rounded-borders q-mt-md q-mb-xs"
              :class="{
                'menu-item round-border border border-text-secondary':
                  $q.screen.gt.sm,
              }"
              data-test="return"
            >
              <q-item-section avatar>
                <q-icon name="fa-solid fa-file" size="xs" />
              </q-item-section>

              <q-item-section>
                <q-item-label>{{ $t('sender.sideDrawer.retun') }}</q-item-label>
              </q-item-section>

              <q-tooltip anchor="center right" self="center left">
                {{ $t('sender.sideDrawer.retunTooltip') }}
              </q-tooltip>
            </q-item>
            <p class="text-center text-xs gt-sm">
              {{ $t('sender.sideDrawer.retun') }}
            </p>
          </div>
        </template>
      </q-list>

      <div class="q-mx-auto q-mb-lg">
        <!-- Log out -->
        <div
          v-if="authStore.isAuthenticated && !authStore.isOwn"
          class="column-md items-center"
        >
          <q-item
            v-ripple
            clickable
            class="rounded-border q-mt-md q-mb-xs"
            :class="{
              'menu-item round-border border border-text-secondary':
                $q.screen.gt.sm,
            }"
            data-test="logout"
            @click="handleLogout"
          >
            <q-item-section avatar>
              <q-icon name="fa-solid fa-arrow-right-from-bracket" size="xs" />
            </q-item-section>

            <q-item-section>
              <q-item-label>{{ $t('common.signOut') }}</q-item-label>
            </q-item-section>

            <q-tooltip anchor="center right" self="center left">
              {{ $t('common.signOut') }}
            </q-tooltip>
          </q-item>
          <p class="text-center text-xs gt-sm">
            {{ $t('common.signOut') }}
          </p>
        </div>
      </div>
    </div>
  </q-drawer>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useQuasar, QDrawerProps } from 'quasar'

import { useAuthStore } from 'stores/sender/auth/auth'
import { useEventsStore } from 'stores/common/events'

import { useDrawer } from 'composables/common/layout/useDrawer'
import { usePrevSource } from 'composables/sender/auth/usePrevSource'

import BasePluginDialog, {
  BasePluginDialogProps,
} from 'components/common/ui/dialog/BasePluginDialog.vue'

const router = useRouter()
const { t } = useI18n()
const $q = useQuasar()

const authStore = useAuthStore()

const { leftDrawerOpen } = useDrawer()

const drawerbehave = ref<QDrawerProps['behavior']>('default')

watchEffect(() => {
  if ($q.screen.gt.sm) {
    drawerbehave.value = 'desktop'
    leftDrawerOpen.value = true
  } else {
    drawerbehave.value = 'mobile'
  }
})

const menuLinks = [
  {
    id: 'sendFile',
    label: () => t('common.sendAFile'),
    tooltip: () => t('sender.sideDrawer.sendFileTooltip'),
    icon: 'fa-solid fa-file-import',
    link: '/',
    login: false,
  },
  {
    id: 'workflows',
    label: () => t('common.workflow', 2),
    tooltip: () => t('sender.sideDrawer.workflowsTooltip'),
    icon: ' account_tree ',
    link: '/app/workflows',
    login: true,
  },
  // {
  //   id: 'schedules',
  //   label: () => t('sender.common.schedules'),
  //   tooltip: () => t('sender.sideDrawer.scheduleTooltip'),
  //   icon: 'fa-solid fa-calendar-days',
  //   link: '/app/schedules',
  //   login: true,
  // },
]

const filterdMenuLinks = computed(() =>
  menuLinks.filter((link) => {
    if (link.login) {
      return authStore.isAuthenticated
    }
    return true
  })
)

onBeforeRouteLeave((to, from, next) => {
  const step = from.meta.step
  if (
    step &&
    step !== 'templates' &&
    step !== 'source' &&
    step !== 'submit' &&
    !to.meta.step
  ) {
    $q.dialog({
      component: BasePluginDialog,
      componentProps: {
        title: t('sender.common.routeGuard.leave'),
        message: t('sender.common.routeGuard.leaveQuestion'),
        cancel: true,
      } as BasePluginDialogProps,
    }).onOk(() => {
      next()
    })
  } else {
    next()
  }
})

// LOGOUT
const handleLogout = () => {
  useAuthStore().logout()
  // Clean Event source
  const { close } = useEventsStore()
  close()
  router.push('/')

  usePrevSource().removeCookies()
}

const returnUrl = process.env.RECEIVER_URL + '/templates'
</script>

<style lang="scss" scoped>
:deep(.q-drawer) {
  color: var(--q-text-secondary);
  background-color: transparent;
}

.menu-item {
  min-height: 36px;
  height: 36px;
  min-width: 36px;
  width: 36px;
}
</style>

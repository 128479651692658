import { defineStore } from 'pinia'
import { api } from 'boot/sender/axios'

import { useErrorStore } from 'stores/common/error'
import { useAuthStore } from 'stores/sender/auth/auth'
import { useConfigStore } from 'stores/sender/config'

import { SuccessApiRes } from 'types/common/api'
import { SelfUserType } from 'types/sender/auth/auth'
import { LocalesCode } from 'src/i18n/locales'

export const useSelfLayoutStore = defineStore('selfLayout', () => {
  // PREFERENCES
  const updatePreferences = async (preferences: { language: LocalesCode }) => {
    try {
      const subdomain = useConfigStore().handle
      const res = await api.put(
        `/send/${subdomain}/preference/update`,
        preferences
      )
      const data: SuccessApiRes<SelfUserType> = res.data

      if (data && data.success) {
        const authStore = useAuthStore()
        if (authStore.user?.user) {
          authStore.user.user = data.payload
        }
        return true
      } else return false
    } catch (error) {
      useErrorStore().updateBackErrors(error)
      return false
    }
  }

  // ORGANISATION
  const switchOrganisations = async (organisationId: string) => {
    try {
      const subdomain = useConfigStore().handle
      const res = await api.put(
        `/send/${subdomain}/switch/switch/${organisationId}`
      )

      const data: SuccessApiRes<SelfUserType> = res.data

      if (data.success) {
        const authStore = useAuthStore()

        // Update user
        if (authStore.user?.user) {
          authStore.user.user = data.payload
        }
        return true
      } else return false
    } catch (error) {
      return false
    }
  }

  // STORE RESET
  // Used to reset the store (is given by default by pinia on options api stores but not on composition api stores)
  const $reset = () => {}

  return {
    updatePreferences,
    switchOrganisations,
    $reset,
  }
})

<template>
  <q-item
    clickable
    class="q-px-lg relative-position"
    :class="{ 'primary-light-bg': !notif.read_at }"
    @click="notifParms.action"
  >
    <q-item-section side class="flex flex-center">
      <q-img
        v-if="notif.context.picture_url"
        :src="notif.context.picture_url"
        class="rounded-borders"
        width="40px"
        height="40px"
      />
      <div
        v-else
        class="bg-primary rounded-borders q-pa-sm flex flex-center"
        style="width: 40px"
      >
        <span class="text-white text-bold text-body1">
          {{ notif.context.name[0]?.toUpperCase() }}
        </span>
      </div>
    </q-item-section>

    <q-item-section
      class="col"
      :class="dense ? 'q-py-xs q-pr-sm q-pl-none' : null"
    >
      <q-item-label>{{ notifParms.title() }}</q-item-label>
      <q-item-label caption>{{ notifDate }}</q-item-label>
    </q-item-section>

    <q-item-section v-if="!dense" class="flex flex-center">
      <q-btn
        :loading="loading"
        flat
        round
        color="negative"
        icon="fa-solid fa-trash"
        @click.stop="handleRemove"
      >
        <q-tooltip>
          {{ $t('common.remove') }}
        </q-tooltip>
      </q-btn>
    </q-item-section>
    <div v-if="!notif.read_at" class="absolute" style="right: 16px; top: 10px">
      <q-badge data-test="not-read" />
    </div>
  </q-item>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { date } from 'quasar'

import { NotifType, NotificationTypes } from 'types/common/notifications'

// PROPS
const props = defineProps<{
  notif: NotifType
  dense?: boolean
  removeNotification: (id: string) => Promise<boolean>
  notificationsAction: Record<NotificationTypes, () => void>
}>()
// EMITS
const emits = defineEmits<{
  notificationRemoved: []
}>()
// MODEL
const notifCount = defineModel<number>('notifCount', {
  required: true,
})

const { t } = useI18n()
const { formatDate, getDateDiff } = date

// REMOVE
const loading = ref(false)
const handleRemove = async () => {
  loading.value = true

  const success = await props.removeNotification(props.notif.id)

  if (success) {
    notifCount.value--
    emits('notificationRemoved')
  }

  loading.value = false
}

// DATE
const notifDate = computed(() => {
  let formatedDate = ''

  const diff = getDateDiff(props.notif.notified_at, new Date(), 'days')

  if (diff === 0) {
    formatedDate = `${t('common.date.today')} ${formatDate(
      props.notif.notified_at,
      'HH:mm'
    )}`
  } else if (diff === -1) {
    formatedDate = `${t('common.date.yesterday')} ${formatDate(
      props.notif.notified_at,
      'HH:mm'
    )}`
  } else {
    formatedDate = formatDate(props.notif.notified_at, 'DD/MM/YYYY HH:mm')
  }

  return formatedDate
})

// NOTIFICATIONS TYPES
const notifParms = computed(() => {
  switch (props.notif.type) {
    case 'received-invitation':
      return {
        icon: 'fa-solid fa-suitcase',
        img: props.notif.context.picture_url,
        color: 'white',
        title: () =>
          t('notifications.invite.title', { orga: props.notif.context.name }),
        action: props.notificationsAction['received-invitation'],
      }

    default:
      return {
        icon: 'fa-solid fa-users',
        title: () => t(''),
        color: 'white',
        action: null,
      }
  }
})
</script>

<template>
  <q-toolbar class="full-height q-px-none">
    <q-btn
      round
      outline
      icon="menu"
      color="muted"
      aria-label="Menu"
      class="lt-sm"
      @click="toggleLeftDrawer"
    />

    <a :href="wtUrl" class="flex items-center q-pl-md" data-test="logo">
      <q-img
        src="~assets/img/logo/logo-color.png"
        alt="WeTransform"
        width="45px"
        fit="contain"
      />
      <div
        class="text-weTransform text-gradient q-ml-sm gt-xs"
        style="font-size: 20px"
      >
        WeTransform
      </div>
    </a>
    <q-chip color="grey-4" class="q-ml-md"> Beta </q-chip>

    <q-space v-if="environement === 'dev'" />

    <q-chip
      v-if="environement === 'dev'"
      color="negative"
      size="xl"
      class="animated pulse infinite slower gt-sm"
    >
      {{ environement }}
    </q-chip>

    <q-space />

    <q-btn
      v-if="authStore.selfUser?.impersonator"
      :label="$t('common.logBack')"
      outline
      color="primary"
      @click="logout('logback')"
    />

    <admin-menu
      v-if="authStore.selfUser && authStore.selfUser.admin"
      :link-actions="adminLinks"
      :check-roles="checkSelfRoles"
    />
    <div
      v-if="authStore.selfUser && authStore.selfActiveOrganisation"
      class="q-mx-sm gt-xs"
    >
      <organisation-switcher
        v-model:menu="orgaMenu"
        :link-actions="organisationLinks"
        :organisations="authStore.selfOrganisations"
        :active-organisation="authStore.selfActiveOrganisation"
        :switch-organisation="switchOrganisation"
        :check-roles="checkSelfRoles"
      />
    </div>
    <lang-switcher class="q-mx-sm" @change-locale="handleLocaleChange" />

    <notifications-btn
      v-if="authStore.selfUser"
      v-model:new-notif="notifStore.newNotif"
      v-model:notif-count="notifStore.notifCount"
      :last-notifications="notifStore.lastNotifications"
      :notifications-action="notificationsAction"
      :fetch-last-notifications="notifStore.fetchLastNotifications"
      :remove-notification="notifStore.removeNotification"
      :see-all-link="{ href: wtUrl + '/account/notifications' }"
    />
    <account-menu
      v-if="authStore.selfUser"
      :link-actions="accountLinks"
      :on-logout="handleLogout"
      :user-identification="{
        pictureUrl: authStore.selfUser?.profile.picture_url,
        firstname: authStore.selfUser?.profile.firstname,
        lastname: authStore.selfUser?.profile.lastname,
        email: authStore.selfUser?.email,
      }"
    />
  </q-toolbar>
</template>

<script setup lang="ts">
import { ref } from 'vue'
// import { useRouter } from 'vue-router'

import { useAuthStore } from 'stores/sender/auth/auth'
import { useSelfLayoutStore } from 'stores/sender/selfLayout/selfLayout'
import { useSelfNotificationsStore } from 'stores/sender/selfLayout/notifications'
// import { useEventsStore } from 'stores/common/events'

import { useDrawer } from 'composables/common/layout/useDrawer'
import { useCheckRoles } from 'composables/sender/auth/useCheckRoles'

import OrganisationSwitcher from 'components/common/layout/organisation/OrganisationSwitcher.vue'
import LangSwitcher from 'components/common/layout/LangSwitcher.vue'
import AccountMenu from 'components/common/layout/AccountMenu.vue'
import AdminMenu from 'components/common/layout/AdminMenu.vue'
import NotificationsBtn from 'components/common/layout/NotificationsBtn.vue'

import { LocalesCode } from 'src/i18n/locales'

const authStore = useAuthStore()
const selfStore = useSelfLayoutStore()
// const router = useRouter()

// Update receiver locale preferences if needed
const handleLocaleChange = (code: LocalesCode) => {
  if (authStore.isAuthenticated) {
    selfStore.updatePreferences({
      language: code,
    })
  }
}

const { toggleLeftDrawer } = useDrawer()

const environement = process.env.INFRA_ENV
const wtUrl = process.env.RECEIVER_URL as string

// ACCOUNT MENU
const accountLinks = {
  profile: {
    href: wtUrl + '/account/profile',
  },
  organisations: {
    href: wtUrl + '/account/organisations',
  },
}

// NOTIFICATIONS
const notifStore = useSelfNotificationsStore()

const notificationsAction = {
  'received-invitation': () => {
    window.location.href = wtUrl + '/account/organisations'
  },
}

// AMDIN MENU
const { checkSelfRoles } = useCheckRoles()
const adminLinks = {
  users: {
    href: wtUrl + '/admin/users',
  },
  roles: {
    href: wtUrl + '/admin/roles',
  },
  services: {
    href: wtUrl + '/admin/billing/services',
  },
  allowance: {
    href: wtUrl + '/admin/billing/allowance',
  },
  purchases: {
    href: wtUrl + '/admin/purchases',
  },
  function: {
    href: wtUrl + '/admin/functions',
  },
  impersonate: {
    href: wtUrl + '/admin/impersonate',
  },
  'import-export': {
    href: wtUrl + '/admin/import-export/functions',
  },
}

// ORGANISATION SWITCHER
const orgaMenu = ref(false)
const organisationLinks = {
  'organisation-basicInfo': {
    href: wtUrl + '/account/organisations/info',
  },
  'organisation-billing': {
    href: wtUrl + '/account/organisations/billing',
  },
  'organisation-members': {
    href: wtUrl + '/account/organisations/members',
  },
  'organisation-roles': {
    href: wtUrl + '/account/organisations/roles',
  },
  'organisation-page': {
    href: wtUrl + '/account/organisations/page',
  },
}

const switchOrganisation = async (id: string) => {
  const success = await selfStore.switchOrganisations(id)
  if (success) {
    window.location.href = wtUrl
    return true
  } else return false
}

// LOGOUT
const handleLogout = () => {
  logout('logout')
}
const logout = async (type: 'logout' | 'logback') => {
  await authStore.logout()
  // Redirect to receiver and logout there
  window.location.href = wtUrl + `/wait/${type}`
}
</script>

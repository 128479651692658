<template>
  <onboarding-btn @on-click="handleOnboardingClick" />
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'

import { useAuthStore } from 'stores/sender/auth/auth'

import OnboardingBtn from 'components/common/layout/onboarding/OnboardingBtn.vue'

import { OnboardingSteps } from 'types/common/onboarding/onboarding'

const router = useRouter()
const authStore = useAuthStore()

const handleOnboardingClick = (step: OnboardingSteps) => {
  actions[step]?.()
}

const receiverUrl = process.env.RECEIVER_URL

const actions: Record<OnboardingSteps, () => void> = {
  // Step 1
  create_template: () => {
    window.location.assign(receiverUrl + '/templates/create')
  },
  add_a_few_columns: () => {
    window.location.assign(
      receiverUrl +
        `/templates?id=${authStore.user?.onboarding_context.template_id}&highlight=columns`
    )
  },
  add_mandatory_column: () => {
    window.location.assign(
      receiverUrl +
        `/templates?id=${authStore.user?.onboarding_context.template_id}&highlight=columns`
    )
  },
  // Step 2
  upload_a_file: () => {
    const onboardingContext = authStore.user?.onboarding_context
    router.push(`/${onboardingContext?.template_handle}`)
  },
  match_your_columns: () => {
    const onboardingContext = authStore.user?.onboarding_context
    router.push(
      `/${onboardingContext?.template_handle}/${onboardingContext?.source_id}/map`
    )
  },
  review_your_data: () => {
    const onboardingContext = authStore.user?.onboarding_context
    router.push(
      `/${onboardingContext?.template_handle}/${onboardingContext?.source_id}/review`
    )
  },
  submit_your_file: () => {
    const onboardingContext = authStore.user?.onboarding_context
    router.push(
      `/${onboardingContext?.template_handle}/${onboardingContext?.source_id}/review`
    )
  },
  // Step 3
  customize_your_upload_page: () => {
    window.location.assign(receiverUrl + '/account/organisations/page')
  },
  invite_your_team_members: () => {
    window.location.assign(receiverUrl + '/account/organisations/members')
  },
  create_your_embed_integration: () => {
    window.location.assign(receiverUrl + '/integrations/embed')
  },
}
</script>

import { useAuthStore } from 'stores/sender/auth/auth'

export const useCheckRoles = () => {
  /**
   * Checks if the user has the specified roles.
   *
   * @param roles - An array of roles to check.
   * @param all - Optional. If true, checks if all roles are present. If false, checks if at least one role is present. Default is true.
   * @returns True if the user has the specified roles, false otherwise.
   */
  const checkSelfRoles = (roles: string[], all = true) => {
    const authStore = useAuthStore()
    if (all) {
      // Check if all roles are present
      return roles.every((role) => authStore.selfUserRoles.includes(role))
    } else {
      // Check if at least one role is present
      return roles.some((role) => authStore.selfUserRoles.includes(role))
    }
  }

  return { checkSelfRoles }
}
